/* app.js css */
body {
  font-family: "Tw Cen MT Std";
}

/* .error-container {
  text-align: left;
} */

.error {
  color: #d00009;
  text-align: left;
}

.outerDiv {
  padding-top: 50px;
  padding-bottom: 50px;
  align-items: center;
  background-color: #f3f4f6;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 170px);
}